<template>
  <div
    v-show="popupVisible"
    class="popup"
  >
    <youtube
      :video-id="currentVideoId"
      @ended="playNextVideo"
      v-if="popupVisible"
      player-width="100%"
      player-height="100%"
      :player-vars="playerOptions"
      style="width: 50%; height: 60%; background-color: #000; border-radius: 6px 0px 0px 6px;"
    />
    <div
      class="queue text-white overflow-scroll"
      style="height: 60%; width: 20%; background-color: #25272C; border-radius: 0px 6px 6px 0px;"
    >
      <button
        class="fixed top-0 right-0 p-5"
        style="z-index: 1; float: right; top: 20%; right: 15%;"
        @click="closePopup"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-14 h-14"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <div
        v-for="(video, index) in videos"
        :key="index"
        class="h-48 flex p-4 items-center"
        :style="{
          borderBottom: '0.5px solid #FFFFFF',
          cursor: 'pointer',
          backgroundColor: index === currentVideoIndex ? '#343D49' : 'transparent'
        }"
        :ref="index === currentVideoIndex ? 'currentVideo' : null"
        @click="playVideo(index)"
      >
        <div>{{ index+1 }}</div>
        <div class="p-5">
          {{ video.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { videos } from '@/content/playlistVideos.json'
import VueYouTubeEmbed from 'vue-youtube-embed'

import { mapGetters } from 'vuex'
Vue.use(VueYouTubeEmbed)

export default {

  name: 'Playlist',
  props: {
    popupVisible: {
      type: Boolean,
      required: true
    },
    isSum: {
      type: Boolean,
      required: true
    },
    startAbility: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    ...mapGetters({
      mainMeasures: 'REPORT/getMainMeasures'
    })
  },
  data () {
    return {
      videos: [],
      currentVideoIndex: 0,
      currentVideoId: '',
      autoplayValue: 0,
      playerOptions: {
        playerVars: {
          autoplay: this.autoplayValue,
          rel: 0
        }
      }
    }
  },
  methods: {
    playVideo (index) {
      this.currentVideoIndex = index
      this.currentVideoId = this.videos[this.currentVideoIndex].videoId
    },
    playNextVideo () {
      this.autoplayValue = 1
      this.currentVideoIndex++
      this.currentVideoId = this.videos[this.currentVideoIndex].videoId
      this.$refs.currentVideo[0].scrollIntoView({ behavior: 'smooth' })
    },
    closePopup () {
      this.$emit('update:popupVisible', false)
    }
  },
  watch: {
    popupVisible (newValue, oldValue) {
      if (newValue && !oldValue) {
        if (this.startAbility) {
          const startVideoIndex = this.videos.findIndex(
            (video) => video.title === this.startAbility + ' - Description'
          )
          if (startVideoIndex !== -1) {
            this.currentVideoIndex = startVideoIndex
            this.currentVideoId = this.videos[startVideoIndex].videoId
            this.$refs.currentVideo[0].scrollIntoView({ behavior: 'smooth' })
          }
        } else {
          this.currentVideoId = this.videos[this.currentVideoIndex].videoId
          this.$refs.currentVideo[0].scrollIntoView({ behavior: 'smooth' })
        }
      }
    }
  },
  updated () {
    this.$refs.currentVideo[0].scrollIntoView()
  },
  mounted () {
    // create playlist
    const playlist = []

    this.mainMeasures.forEach((measure) => {
      const label = measure.label
      const stanine = measure.stanine

      const trait = videos[label]

      if (trait) {
        if (trait.description) {
          playlist.push({ title: trait.description.title, videoId: trait.description.videoId })
        }

        const videoArray = stanine > 4 ? trait.high : trait.low
        if (this.isSum && videoArray) {
          const videosToAdd = videoArray.slice(0, 2)
          videosToAdd.forEach((video) => {
            playlist.push({ title: video.title, videoId: video.videoId })
          })
        } else if (!this.isSum && videoArray) {
          videoArray.forEach((video) => {
            playlist.push({ title: video.title, videoId: video.videoId })
          })
        }
      }
    })
    this.videos = playlist
    this.currentVideoId = this.videos[this.currentVideoIndex].videoId
  }

}
</script>

<style lang="scss">
@import "./playlist.scss";
</style>
